export default {
  data () {
    return {
      pagination: {
        count: 0,
        limit: 10
      },
      currentPage: 1
    }
  },

  created () {
    const { page, limit } = this.$route.query
    if (page) {
      this.currentPage = +page
    }
    if (limit) {
      this.pagination.limit = +limit
    }
  }
}
