<template>
  <modal
    :name="modalName"
    :max-width="540"
    :min-width="280"
    :scrollable="$currentDevice.isMobile"
    width="90%"
    height="auto"
    :adaptive="true"
    @closed="onClosed"
  >
    <div class="modal-container">
      <div>
        <svg-icon
          class="success__icon"
          name="success"
        />
        <h2 class="modal-container__heading">
          Запрос на создание отчета создан
        </h2>
        <p class="modal-container__text">
          Вы можете получить файл для скачивания отчета в таблице в разделе “Отчеты”
        </p>
        <x-btn
          type="button"
          block
          @click="$modal.hide(modalName)"
        >
          Понятно
        </x-btn>
      </div>

      <close-button
        @click="$modal.hide(modalName)"
      />
    </div>
  </modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import marketplaces from '@/constants/marketplaces'
import CloseButton from '@/components/Interface/CloseButton.vue'
import companyDetails from '@/plugins/companyDetails'

const { mapActions } = createNamespacedHelpers('marketplaces')
const { mapActions: mapTrackingActions } = createNamespacedHelpers('tracking')

export default {
  components: {
    CloseButton
  },

  data () {
    return {
      modalName: 'report-request-modal',
      form: {
        marketplace: [],
        phone: ''
      },
      marketplaces,
      isLoading: false,
      formSended: false,
      companyDetails
    }
  },

  methods: {
    ...mapActions(['requestConsultation']),
    ...mapTrackingActions(['setEvent']),

    async onSubmit () {
      try {
        this.isLoading = true

        await this.requestConsultation({
          phone_number: this.form.phone,
          marketplace_codes: this.form.marketplace
        })

        this.formSended = true
      } finally {
        this.isLoading = false
      }
    },

    onClosed () {
      this.form.marketplace = []
      this.form.phone = ''
      this.formSended = false
    },

    onConsultation () {
      this.setEvent({
        name: 'mmp_shops_consultation',
        product: 'MMP',
        screen: 'mmp_shops',
        marketplace_code: this.form.marketplace.join()
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-container
    max-width 448px
    margin 0 auto
    padding 41px 0 36px
    font-size 14px
    color #54555f

    &__heading
      margin-bottom 5px
      text-align center

    &__text
      font-family $font-family
      text-align center
      font-size 14px
      color #7B7B85
      margin-bottom 20px

    svg
      height 60px
      width 100%
      text-align center
      margin 20px 0
</style>
