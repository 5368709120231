<template>
  <modal
    :name="modalName"
    :max-width="540"
    :min-width="280"
    :scrollable="$currentDevice.isMobile"
    width="90%"
    height="auto"
    :adaptive="true"
  >
    <div class="modal-container">
      <div>
        <svg-icon
          class="warning__icon"
          name="exclamation"
        />
        <h2 class="modal-container__heading">
          Рекомендации
        </h2>
        <ul class="modal-container__recommend-list">
          <template v-for="(recommend, idx) in recommendations">
            <li
              :key="`recommendation-${idx}`"
              class="modal-container__recommend-list__recommend-item"
            >
              <svg-icon
                v-if="recommend.action"
                :name="statusIcons[recommend.action]"
                class="modal-container__recommend-list__recommend-item__icon"
              />
              <span class="text">{{ recommend.text }}</span>
            </li>
          </template>
        </ul>
        <x-btn
          type="button"
          block
          @click="$modal.hide(modalName)"
        >
          Понятно
        </x-btn>
      </div>

      <close-button
        @click="$modal.hide(modalName)"
      />
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'

export default {
  components: {
    CloseButton
  },
  props: {
    recommendations: {
      type: Array,
      default: () => ([])
    },
    icon: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      modalName: 'report-recommendation-modal',
      statusIcons: {
        reload: 'reload',
        stop: 'error'
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-container
    max-width 448px
    margin 0 auto
    padding 41px 0 36px
    font-size 14px
    color #54555f
    text-align center

    svg.warning__icon
      text-align center
      margin 20px 0
      width 50px
      height 50px
      border-radius 25px
      border 5px solid red

    &__heading
      margin-bottom 5px
      text-align center

    &__recommend-list
      list-style none
      margin-top 20px
      margin-bottom 10px

      &__recommend-item
        display inline-flex
        margin-bottom 15px

        svg
          width 25px
          height 25px
          margin auto 15px auto 0

        .text
          font-family $font-family
          text-align justify
          font-size 14px
          color #7B7B85

</style>
