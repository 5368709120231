<template>
  <div class="export-list">
    <heading class="export-list__heading">
      Выгрузка из системы
    </heading>

    <export-list />
  </div>
</template>

<script>
import Heading from '@/components/Common/Heading.vue'
import ExportList from '@/components/Exports/ExportList.vue'

export default {
  components: {
    Heading,
    ExportList
  }

}
</script>
