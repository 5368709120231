export default Object.freeze([{
  name: 'ID',
  key: 'id'
}, {
  name: 'Дата создания',
  key: 'created',
  type: 'created',
  styles: { maxWidth: '200px' }
}, {
  name: 'Период',
  key: 'period',
  type: 'period',
  styles: { maxWidth: '200px' }
}, {
  name: 'Магазин',
  key: 'shop_name',
  type: 'shopName'
}, {
  name: 'Тип',
  key: 'operation_type'
}, {
  name: 'Автор',
  key: 'initiated_by'
}, {
  name: 'Статус',
  key: 'status',
  type: 'status'
}, {
  name: '',
  key: 'download_url',
  type: 'downloadUrl'
}, {
  name: '',
  key: 'reload',
  type: 'reload'
}, {
  name: '',
  key: 'pause',
  type: 'pause'
}])
