<template>
  <modal
    height="auto"
    :name="modalName"
  >
    <div class="modal-body">
      <close-button @click="$modal.hide(modalName)" />

      <heading level="3">
        Создать отчет
      </heading>

      <div class="">
        <x-form-item
          name="Выберите магазин"
          label="Выберите магазин"
        >
          <x-select
            v-model="selectShop"
            shadow
            track-by="id"
            label="label"
            :options="shops"
            :searchable="false"
            :allow-empty="false"
            placeholder="Магазин"
            @select="changeShop"
          />
        </x-form-item>
        <x-form-item
          name="Выберите период"
          label="Выберите период"
        >
          <datepicker
            :max-date="new Date()"
            :default-period="filtredPeriod"
            :value="period"
            @change="date => period = date"
            @change-default-period="p => filtredPeriod = p"
          />
        </x-form-item>
        <x-form-item
          name="Выберите отчёт"
          label="Выберите отчёт"
        >
          <x-select
            v-model="selectReport"
            shadow
            track-by="id"
            label="label"
            :options="reportOptions"
            :searchable="false"
            :allow-empty="false"
            placeholder="Отчёт"
          />
        </x-form-item>
        <x-form-item
          v-if="false"
          name="Доп. параметр"
          label="Доп. параметр"
        >
          <x-select
            v-model="selectReport"
            shadow
            track-by="id"
            label="label"
            :options="reportOptions"
            :searchable="false"
            :allow-empty="false"
            placeholder="Доп. параметр"
          />
        </x-form-item>
        <x-form-item
          v-if="false"
          name="Доп. параметр"
          label="Доп. параметр"
        >
          <x-select
            v-model="selectReport"
            shadow
            track-by="id"
            label="label"
            :options="reportOptions"
            :searchable="false"
            :allow-empty="false"
            placeholder="Доп. параметр"
          />
        </x-form-item>
      </div>

      <div>
        <x-btn
          outline
          type="button"
          class="modal-body__btn"
          @click="$modal.hide(modalName)"
        >
          Отмена
        </x-btn>
        <x-btn
          primary
          class="modal-body__btn"
          @click="downloadReport"
        >
          Заказать отчет
        </x-btn>
      </div>
    </div>
  </modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import CloseButton from '@/components/Interface/CloseButton.vue'
import Heading from '@/components/Common/Heading.vue'
import Datepicker from '@/components/Interface/Datepicker.vue'
import dateFormat from '@/utils/date-formatter'
import moment from 'moment'
import { DAY_IN_MS } from '@/constants/timings'
import { getMarketplaceIcon } from '@/utils/getMarketplaceIcon'
import toastNotification from '@/utils/toast-notification'
import CalendarPeriods from '@/constants/calendar-periods'
const { mapActions, mapGetters } = createNamespacedHelpers('marketplaces')
const { mapActions: mapReportActions } = createNamespacedHelpers('reports')
const { mapActions: mapMarketplaceActions } = createNamespacedHelpers('marketplaces')

const DOWNLOAD_FORMAT = 'YYYY-MM-DD'
const WEEK_AGO_MS = Date.now() - DAY_IN_MS * 7

const today = new Date()

export default {
  components: {
    CloseButton,
    Heading,
    Datepicker
  },

  data () {
    return {
      modalName: 'create-report-modal',
      openInNewTab: false,
      filtredPeriod: CalendarPeriods.CURRENT_WEEK,
      shopOptions: [],
      selectShop: null,
      selectReport: null,
      reportTypes: [],
      period: {
        start: dateFormat(WEEK_AGO_MS, DOWNLOAD_FORMAT),
        end: dateFormat(today, DOWNLOAD_FORMAT)
      },
      errorMessage: ''
    }
  },

  computed: {
    ...mapGetters(['filteredShopList']),

    shops () {
      return this.filteredShopList.map(shop => ({
        ...shop,
        label: shop.shop.name + ' (' + shop.id + ')',
        $icon: getMarketplaceIcon(shop.marketplace_code)
      }))
    },

    reportOptions () {
      const result = []
      if (this.selectShop) {
        const foundShop = this.reportTypes.find(el => el.shop_id === this.selectShop.id)
        for (const key in foundShop.reports) {
          result.push({
            id: key,
            label: foundShop.reports[key]
          })
        }
      }
      return result
    }
  },

  async created () {
    await this.getShopListCombo()

    const { data, isSuccess } = await this.getReportTypes()
    if (isSuccess) {
      this.reportTypes = data
    }
  },

  methods: {
    ...mapActions(['getShopListCombo']),
    ...mapReportActions(['getReportList', 'createReport']),
    ...mapMarketplaceActions(['getReportTypes']),

    async downloadReport () {
      if (this.validateData(this.period.start, this.period.end)) {
        const { isSuccess } = await this.createReport({
          shop_id: this.selectShop.id,
          from: this.period.start,
          to: this.period.end,
          report_type: this.selectReport?.id
        })
        if (isSuccess) {
          this.$modal.hide(this.modalName)
          this.$emit('close-modal')
        } else {
          toastNotification.error('Ошибка')
        }
      } else {
        this.$emit('show-error-modal', this.errorMessage)
      }
    },

    validateData (start, end) {
      if (!this.selectShop.is_active) {
        this.errorMessage = 'Магазин не активен'
        return false
      }

      if (this.selectReport?.id === 'weekly_report') {
        this.errorMessage = 'Выберите период с понедельника по воскресенье'
        return moment(start).weekday() === 1 && moment(end).weekday() === 0
      } else if (this.selectReport?.id === 'daily_report') {
        this.errorMessage = 'Период отчета должен быть равен 1 дню'
        return dateFormat(start) === dateFormat(end)
      } else if (this.selectReport?.id === 'supply_forecast') {
        const days = moment(end).diff(moment(start), 'days')
        this.errorMessage = `Период отчета должен быть в диапазоне от 1 дня до 90. Выбрано ${days + 1} дней`
        return days < 90
      } else if (this.selectReport?.id === 'monthly_report') {
        this.errorMessage = 'Выберите период с 1-го по последнее числа месяца'
        return moment(start).date() === 1 && moment(end).date() === moment(start).daysInMonth()
      } else {
        this.errorMessage = 'Выберите тип отчета'
        return false
      }
    },

    changeShop () {
      this.selectReport = null
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-body
    padding 32px
    text-align left

    &__text
      font-size 14px
      color #3c3c47

    &__btn
      width 257px
      margin-top 20px

  .x-form-item
    margin-bottom: 10px
</style>
