<template>
  <modal
    :name="modalName"
    :max-width="540"
    :min-width="280"
    :scrollable="$currentDevice.isMobile"
    width="90%"
    height="auto"
    :adaptive="true"
  >
    <div class="modal-container">
      <div>
        <svg-icon
          class="success__icon"
          name="error"
        />
        <h2 class="modal-container__heading">
          Ошибка
        </h2>
        <p class="modal-container__text">
          {{ errorMessage }}
        </p>
        <x-btn
          type="button"
          block
          @click="$modal.hide(modalName)"
        >
          Понятно
        </x-btn>
      </div>

      <close-button
        @click="$modal.hide(modalName)"
      />
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'

export default {
  components: {
    CloseButton
  },
  props: {
    errorMessage: {
      type: String,
      default: 'Выберите период с понедельника по воскресенье'
    }
  },

  data () {
    return {
      modalName: 'report-error-modal'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-container
    max-width 448px
    margin 0 auto
    padding 41px 0 36px
    font-size 14px
    color #54555f

    &__heading
      margin-bottom 5px
      text-align center

    &__text
      font-family $font-family
      text-align center
      font-size 14px
      color #7B7B85
      margin-bottom 20px

    svg
      height 60px
      width 100%
      text-align center
      margin 20px 0
</style>
