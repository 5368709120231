<template>
  <div
    class="badge"
    :style="statusStyle"
  >
    <svg-icon
      v-if="icon"
      :name="icon"
      class="badge__icon"
    />
    <div>
      <div class="badge__text">
        {{ statusText }}
        <span
          v-if="showBtn"
          class="badge__text__btn"
          @click="$emit('show-recommendation')"
        >
          Подробнее
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Statuses, {
  statusStyles
} from '@/constants/statuses'

export default {
  props: {
    status: {
      type: String,
      default: 'default'
    },
    statusList: {
      type: Object,
      default: () => ({})
    },
    icon: {
      type: String,
      default: ''
    },
    defaultStyle: {
      type: Object,
      default: () => ({})
    },
    showBtn: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    statusText () {
      return this.statusList[this.status] || Statuses[this.status]
    },

    statusStyle () {
      if (this.icon || !this.status) {
        return
      }

      return statusStyles[this.status.toLowerCase()] || this.defaultStyle
    }
  }
}
</script>

<style lang="stylus" scoped>
  .badge
    display inline-flex
    align-items center
    padding 1px 6px
    border-radius 20px

    &__text
      display inline-block

      &__btn
        font-size 14px
        cursor pointer
        display block
        color #5b40ff
        font-weight 600

    &__icon
      width 13px
      height 13px
      margin-right 7px
      display inline-block

      &.svg-icon--pause
        fill #f9dd8d

  >>>.v-popover
    .trigger
      display flex !important
      align-items center
</style>
